import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    primary: {
      main: 'rgba(45, 171, 207, 1)', // bleu
    },
    secondary: {
      main: 'rgba(147, 198, 83, 1)', // vert
      hover: 'rgba(75, 159, 70, 1)', // vert foncé
    },
    warning: {
      main: 'rgba(244, 166, 35, 1)', // orange
    },
    error: {
      main: 'rgba(230, 67, 49, 1)', // rouge
      hover: 'rgba(171, 51, 38, 1)', // rouge foncé
    },
    neutral: {
      main: 'rgba(88, 88, 88, 1)', // gris
    },
    textBlack: 'rgb(88, 88, 88)',
    blueLight: 'rgb(67, 133, 245)',
    blueDark: 'rgb(39, 46, 92)',
    white: 'rgba(255, 255, 255, 1)', // blanc
    transparent: 'rgba(0, 0, 0, 0)', // transparent
  },
  button: {
    primary: {
      text: 'rgba(255, 255, 255, 1)', // blanc
      hover: 'rgba(255, 255, 255, 1)', // blanc
      background: 'rgba(45, 171, 207, 1)', // bleu
      hoverBackground: 'rgba(27, 131, 186, 1)', // bleu foncé
    },
    secondary: {
      text: 'rgba(255, 255, 255, 1)', // blanc
      hover: 'rgba(255, 255, 255, 1)', // blanc
      background: 'rgba(147, 198, 83, 1)', // vert
      hoverBackground: 'rgba(75, 159, 70, 1)', // vert foncé
    },
    ternary: {
      text: 'rgba(255, 255, 255, 1)', // blanc
      hover: 'rgba(255, 255, 255, 1)', // blanc
      background: 'rgba(230, 47, 69, 1)', // rouge
      hoverBackground: 'rgba(203, 39, 30, 1)', // rouge foncé
    },
    neutral: {
      text: 'rgba(255, 255, 255, 1)', // blanc
      hover: 'rgba(255, 255, 255, 1)', // blanc
      background: 'rgba(88, 88, 88, 1)', // gris
      hoverBackground: 'rgba(214, 215, 216, 1)', // gris clair
    },
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  border: {
    light: 'rgb(222, 222, 222)',
    dark: 'rgb(198, 198, 198)',
  },
  background: {
    paper: '#ffffff',
    default: 'rgba(0, 0, 0, 0)',
    light: 'rgb(241, 242, 246)',
    lighter: 'rgb(251, 252, 255)',
    dark: 'rgba(178, 179, 179, 0.25)',
    action: 'rgba(45, 171, 207, 1)',
  },
};

export default theme;

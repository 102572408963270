import { useEffect, useState } from "react";
import axios from "axios";

import {
  getStoredToken,
  resetAuthState,
  saveAuthState,
  sendToken,
} from "../../../utils";
import AuthException from "../../../exceptions/AuthException";
import type AuthResponse from "../../../interfaces/authResponse";
import type Credentials from "../../../interfaces/credentials";
import ErrorInsert from "./ErrorInsert";
import Card from "./Card";
import LoginForm from "./LoginForm";
import Logo from "./Logo";
import View from "../../View";
import WelcomeText from "./WelcomeText";
import config from "../../../config";

import logoTheme from "../../../ui/assets/img/logo-AIRPARTENAIRESANTE.png";
// import logoTheme from "../../../ui/assets/img/logo-ads.png";
// import logoTheme from "../../../ui/assets/img/logo-SANTELYS.png";
// TODO: rétablir prise en compte variable d'environnement
// const logoTheme = require(`../../../ui/assets/img/logo-${process.env.REACT_APP_THEME}.${process.env.REACT_APP_LOGO_FORMAT}`);
// const logoTheme = require(`../../../ui/assets/img/logo-SANTELYS.png`);

const loginUrl = `${config.serverURL}/auth/login`;

export default function LoginView() {
  const [errorMessage, setErrorMessage] = useState("");
  const queryString = window.location.search;
  const urlParams: any = new URLSearchParams(queryString);
  const clientUrl = urlParams.get("src");
  const theme = urlParams.get("theme") || process.env.REACT_APP_THEME;
  const storedToken = getStoredToken();

  async function authenticate({ password, username }: Credentials) {
    try {
      const { data } = await axios(loginUrl, {
        method: "POST",
        data: { username, password },
        withCredentials: true,
      });
      return data as AuthResponse;
    } catch (error: any) {
      console.error(error);
      throw new AuthException(error.response.status);
    }
  }

  async function handleSubmit(credentials: Credentials) {
    setErrorMessage("");

    if (!credentials.username || !credentials.password) {
      setErrorMessage("Veuillez saisir vos identifiants");
      return;
    }

    try {
      const authResponse = await authenticate(credentials);
      console.log("authResponse: ", authResponse);
      saveAuthState(authResponse);
      sendToken(authResponse.token, clientUrl);
    } catch (error: any) {
      setErrorMessage(error.message);
      throw error;
    }
  }

  useEffect(() => {
    const verifyToken = (token: string) => {
      axios(`${process.env.REACT_APP_SERVER_URL}/auth/verify`, {
        method: "POST",
        data: { token },
        withCredentials: true,
      })
        .then((response) => {
          saveAuthState(response as unknown as AuthResponse);
          sendToken(token, clientUrl);
        })
        .catch((error) => {
          resetAuthState();
        });
    };

    if (!!storedToken) {
      verifyToken(storedToken);
    }
  }, [clientUrl, storedToken]);

  // if (!!storedToken) {
  //   try {
  //     axios
  //       .post(`${process.env.REACT_APP_SERVER_URL}/auth/verify`, {
  //         token: storedToken,
  //       })
  //       .then((response) => {
  //         console.log(response.data);
  //         sendToken(storedToken, clientUrl);
  //         return null;
  //       });
  //     // verifyToken(storedToken, config.jwtPUBLIC!, {
  //     //   algorithms: ["RS512"],
  //     // });
  //   } catch (error) {
  //     // TODO refresh token
  //     resetAuthState();
  //   }
  // }

  if (
    theme === "adene" ||
    theme === "nomad" ||
    theme === "airpartenairesante" ||
    theme === "alair"
  ) {
    return (
      <View title="Connexion">
        <Card stheme={theme}>
          <LoginForm stheme={theme} onSubmit={handleSubmit} />
          {errorMessage && (
            <ErrorInsert stheme={theme}>{errorMessage}</ErrorInsert>
          )}
        </Card>
      </View>
    );
  }

  return (
    <View title="Connexion">
      <Card stheme={theme}>
        <Logo src={logoTheme} alt="" />
        <WelcomeText>
          Pour commencer, veuillez vous connecter avec vos identifiants
        </WelcomeText>
        <LoginForm stheme={theme} onSubmit={handleSubmit} />
        {errorMessage && (
          <ErrorInsert stheme={theme}>{errorMessage}</ErrorInsert>
        )}
      </Card>
    </View>
  );
}
